
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header{
	width:100%;
	min-width: $min-width;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;	
}

.header {
 	&-top {
		 background-color: #fff;
		ul {
			display: flex;
			justify-content: space-between;
			background-color: #d5c7b1;
			li {
				background-color: #ffffff;
				border-right: 1px solid rgba($color: #000000, $alpha: .1);
				&:last-of-type {
					border-right: none;
				}
				&:nth-child(2),
				&:nth-child(3) {
					width: 33.3%;
				}
				a {
					padding: 22px 23px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 100%;
					width: 100%;
					span {
						font-size: 16px;
						font-weight: 700;
						line-height: 20px;
						display: block;
						color: #006ba0;
						transition: $transition;
						&.header-top__text-green {
							color: #01ad19;
						}
					}
					&:hover {
						background-color: #f9faf4;
						span {
							color: #01ad19;
						}
					}
					p {
						font-size: 14px;
						color: #5a5a5a;
						line-height: 18px;
						margin-top: 7px;
					}
				}
				&:first-child {
					a {
						flex-direction: column;
						align-items: flex-end;
						justify-content: center;
						span {
							text-align: center;
							width: 100%;
							font-size: 18px;
						}
					}
					.header-top__info {
						width: 100%;
						margin-left: 5px;
						margin-top: 5px;
					}
				}
			}
		}
		&__text-blue {
			color: #006ba0;
		}
		&__text-green {
			color: #01ad19;
		}
		&__info {
			width: 52%;
		}
	}
	&-nav {
		background-color: #0089bf;
		padding: 0 16px;
		ul {
			li {
				display: inline-block;
				margin-right: 20px;
				a {
					color: #ffffff;
					font-weight: 700;
					line-height: 22px;
					display: block;
					padding: 11px 0;
					padding-bottom: 12px;
					&:hover {
						color: #ffe345;
					}
				}	
			}
		}
	} 
}

.header-btn__nav {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
	padding: 25px;
	// border-left: 1px solid #d5c7b1;
	border-left: 1px solid rgba($color: #000000, $alpha: .1);
	// height: 75px;
	height: 71px;
}

.button-nav {
	width: 20px;
	position: relative;
	display: block;
	span{
		display: block;
	    margin: 4px auto;
	    width: 20px;
	    background-color: #006ba0;
	    height: 2px;
	    transition: 0.3s ease;
	}
	&.active{
		margin-left: 0;
		span{
			&:first-child{
				transform: rotate(-45deg);
			    position: absolute;
			    margin: 0;
				width: 30px;
				left: -5px;
				top: 6px;
			}
			&:nth-child(2){
				background-color: transparent;
			}
			&:nth-child(3){
				transform: rotate(45deg);
				position: absolute;
				right: -5px;
				width: 30px;
				top: 6px;
				margin: 0;
			}
		}
	}
}


@media screen and (max-width: 992px){
	body {
		background-image: none!important;
		background-color: #d5c7b1;
	}
	.header-top {
		background-color: transparent;
	}
	.header-top ul {
		flex-wrap: wrap;
		li {
			width: 33.3%;
			background-color: #fff;
			a {
				padding-left: 12px;
				padding-right: 0;
			}
			&:first-of-type {
				width: 100%;
				margin-bottom: 20px;
				a {
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					padding: 13px 31px 15px;
					&:hover {
						background-color: transparent;
					}
					img {
						width: 52px;
					}
					.header-top__info {
						width: auto;
						span {
							display: inline-block;
							width: auto;
						}
					}
				}
			}
		}
	}
	.header-nav {
		display: none;
		&.show {
			display: block;
			position: absolute;
			width: 100%;
			top: 70px;
			z-index: 10;
		}
	}
	.header-btn__nav {
		display: block;
	}
	.fixed-block {
		display: none;
	}
	.header-top ul li:first-child .header-top__info {
		width: 100%;
		margin-left: 9px;
		margin-top: -2px;
	}
	.header-top ul li a {
		padding: 28px 10px;
	}
	.header-top ul li:nth-child(2) {
		a {
			padding-left: 0;
		}
	}
	.header-top ul li:nth-child(4) {
		a {
			padding-left: 18px;
		}
	}
}


@media screen and (max-width: 767px) {
	.header-nav ul li {
		width: 100%;
		margin: 0;
	}
	.header-top ul li {
		display: none;
	}
	.header-top ul li:nth-child(1) {
		display: block;
		margin-bottom: 0;
	}
	.header-top ul li:first-of-type a {
		padding: 11px 31px 10px;
		padding-left: 15px;
	}
	.header-top ul li:first-of-type a img {
		width: 61px;
	}
	.header-top ul li:first-child .header-top__info {
		margin-left: 14px;
	}
	.header-btn__nav {
		padding-left: 23px;
		padding-right: 23px;
	}
	.header-top ul li:first-of-type a .header-top__info span {
		display: block;
		text-align: left;
	}
}


/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer {
	text-align:left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;	
}

.footer {
	background-color: #006ba0;
	padding-top: 98px;
	padding-bottom: 25px;
	margin-top: -78px;
	margin-bottom: 0;
	&-logos {
		background-color: #ffffff;
		ul {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 133px;
			padding-left: 47px;
			padding-right: 24px;
			li {
				a {
					&:hover {
						opacity: .7;
					}
				}
			}
		}
	}
	&-nav {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		ul {
			li {
				margin-bottom: 14px;
				span {
					font-weight: 700;
					display: block;
					margin-bottom: 20px;
					color: #ffffff;
					font-size: 14px;
				}
				a {
					color: #ffffff;
					font-size: 14px;
					font-weight: 400;
					&:hover {
						color: #ffe345;
					}
				}
			}
		}
		&__col {
			max-width: 138px;
			&:first-of-type {
				max-width: 165px;
				li {
					span {
						font-weight: 700;
						line-height: 24px;
						font-size: 18px;
						margin-top: -6px;
						margin-bottom: 17px;
					}
				}
			}
		}
		&__social {
			display: flex;
			margin-top: 25px;
			li {
				margin-right: 10px;
				a {
					&:hover {
						opacity: 0.7;
					}
				}
			}
			
		}
	}
}



@media screen and (max-width: 992px){
	.footer {
		margin-top: 0;
		padding: 20px 30px;
		&-nav {
			margin-top: 15px;
			padding-bottom: 19px;
		}
		
	}
	.footer-nav__col {
		display: none;
	}
	.footer-nav__col:first-of-type {
		max-width: 100%;
		display: flex;
		flex-direction: row;
		padding: 5px 0;
		align-items: center;
		li {
			margin: 0;
			margin-right: 30px;
			span {
				margin: 0;
			}
		}
		ul {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0;
			
			&.footer-nav__social {
				li {
					margin-right: 10px;
				}
			}
		}
	}
	.footer-nav__col:first-of-type li span {
		font-size: 18px;
		margin-left: 0;
	}
}


@media screen and (max-width: 767px) {
	.footer {
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
		.wrapper {
			min-width: auto;
		}
	}
	.footer-logos {
		width: 100%;
		ul {
			padding-left: 28px;
			flex-wrap: wrap;
			height: auto;
			padding: 25px;
			padding-bottom: 5px;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			li, li:first-of-type, li:nth-child(2) {
				width: 30%;
				text-align: center;
				margin: -5px;
				margin-bottom: 20px;
			}
		}
	}
	.footer-nav__col:first-of-type ul {
		justify-content: start;
		text-align: left;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.footer-nav__col:first-of-type li {
		&:first-of-type {
			width: 100%;
			margin-bottom: 10px;
		}
	}
	.footer-nav__col:first-of-type {
		flex-direction: column;
	}
	.footer-nav__col:first-of-type ul.footer-nav__social {
		flex-direction: row;
		align-self: flex-start;
		margin-top: 20px;
		li {
			&:first-of-type {
				width: auto;
			}
		}
	}
	.footer-nav {
		padding-bottom: 0;
		margin-top: 22px;
	}
	.footer-nav__col:first-of-type li span {
		font-size: 16px;
	}
}
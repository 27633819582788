/* reset */
$min-width: 320px; 
$max-width: 1060px; 
$base-bg: #FFF; 
$base-font-size: 16px; 
$base-line-height: 18px; 
$base-font-family: 'Ubuntu', sans-serif; 
$custom-font-family: 'Ubuntu', sans-serif; 
$link-color: #100da9;
$transition: all 0.2s linear;

/* main variables */

//text, titles
$text-color: #000;
$title-color: #000;
$title-font-family: $custom-font-family;

$font-size-h1: 36px;
$line-height-h1: 42px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 28px;
$line-height-h3: 32px;

//main styles

.main-wrapper{
	padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
}
.wrapper{
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 40px 0 40px;
	margin: 0 auto;
	position: relative;
}

/* titles */
p {	
	@include font($base-font-size,$base-line-height,$text-color);
	font-weight: 400;	
}
h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 30px;
	color: #006ba0;
}
h3 {
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	color: #006ba0;
}
h4 {
	color: #006ba0;
	font-size: 20px;
	font-weight: 700;
	line-height: 36px;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}


		/* loader */
.loaded .main-wrapper{visibility:hidden;}
.icon-load{background:url(../img/loader.gif) no-repeat left top;width:40px;height:40px;position:fixed;left:50%;top:50%;margin-left:-20px;margin-left:-20px;display:none;}
.loaded .icon-load{display:block;}
.loaded {background-image:none;}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {	
	min-width: $min-width;
	text-align:left;
    width: 100%;
	order: 2;
    -webkit-order: 2;
	flex-grow: 1;
    -webkit-flex-grow: 1;
}

body {
	background-image: url(../img/main-bg.jpg);
	// background-size: cover;
	// background-position: top;
	// background-repeat: no-repeat;
}

.btn {
	height: 60px;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
	border: none;
	border-radius: 3px;
	background-color: #ffe345;
	outline: none;
	width: 100%;
	max-width: 252px;
	color: #3c3c3c;
	font-size: 18px;
	font-weight: 700;
	display: block;
	text-align: center;
	line-height: 60px;
	&:hover {
		background-color: #00a1e1;
		color: #ffffff;
	}
	&-small {
		max-width: 149px;
		height: 40px;
		line-height: 40px;
		font-weight: 400;
	}
}

.main-block {
	background-color: #fff;
	padding: 47px 30px;
	margin-top: 20px;
	p {
		margin-top: 20px;
		font-size: 18px;
		color: #3c3c3c;
		line-height: 25px;
	}
	&__link {
		color: #006ba0;
		font-weight: 700;
		display: block;
		margin-top: 12px;
		font-size: 18px;
		img {
			width: 10px;
		}
		&:hover {
			
			color: #00a1e1;
			img {
				// opacity: .8;
				filter: invert(39%) sepia(83%) saturate(1750%) hue-rotate(169deg) brightness(101%) contrast(101%);
			}
		}
	}
	h2 {
		margin-bottom: 25px;
	}
}

.main-block__table {
	margin-top: 16px;
	
	&-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&-col:nth-child(1) {
		width: 42%;
		padding-left: 20px;
		display: flex;
		align-items: center;
		a {
			display: flex;
			align-items: center;
			color: #006ba0;
			font-size: 18px;
			font-weight: 700;
			&:hover {
				color: #00a1e1;
			}
			img {
				margin-right: 20px;
			}
		}
	}
	&-col:nth-child(2) {
		width: 17%;
	}
	&-col:nth-child(3) {
		width: 11%;
	}
	&-col:nth-child(4) {
		width: 28%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 20px;
	}
	&-block {
		margin-top: 10px;
		box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		background-color: #ffffff;
		.main-block__table-row {
			padding-top: 22px;
    		padding-bottom: 22px;		
		}
		&-bottom {
			background-color: #f5f8f9;
			padding: 15px 20px;
			color: #8c8c8c;
			font-size: 14px;
			font-weight: 400;
			a {
				color: #8c8c8c;
				font-size: 14px;
				font-weight: 400;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&-stars {
		display: flex;
		li {
			margin-right: 4px;
		}
	}
	&-head {
		.main-block__table-col {
			color: #8c8c8c;
			font-size: 14px;
			font-weight: 400;
			line-height: 36px;
			padding: 0;
		}
	}
	&-bonus {
		color: #006ba0;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
	}
	&-num {
		width: 30px;
		height: 30px;
		background-color: #0089bf;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		margin-right: 20px;
	}
}

.question-block {
	border-radius: 4px;
	background-color: #e8f3f6;
	padding: 30px 20px;
	padding-top: 22px;
	position: relative;
	margin-top: 30px;
	&__viking {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	&__item {
		margin-top: 10px;
		box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		background-color: #ffffff;
		padding: 0 20px;
		max-width: 719px;
		position: relative;
		z-index: 10;
		&-content {
			display: none;
			padding-bottom: 27px;
			p {
				margin: 0;
			}
		}
		&-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #006ba0;
			font-size: 18px;
			font-weight: 700;
			cursor: pointer;
			padding: 24px 0;
			transition: $transition;
			img {
				display: block;
				transition: .2s transform linear;
			}
			p {
				transition: $transition;
			}
			&:hover {
				color: #00a1e1;
				p {
					color: #00a1e1!important;
				}
				img {
					// opacity: .7;
					filter: invert(39%) sepia(83%) saturate(1750%) hue-rotate(169deg) brightness(101%) contrast(101%);
				}
			}
		}
		&.active {
			.question-block__item-title {
				color: #00a1e1;
				padding-bottom: 13px;
				img {
					transform: rotateZ(180deg);
					// opacity: .7;
					filter: invert(39%) sepia(83%) saturate(1750%) hue-rotate(169deg) brightness(101%) contrast(101%);
				}
			}
		}
	}
	h3 {
		margin-bottom: 23px;
	}
}

.online-casino-block {
	margin-top: 57px;
}

.top-casino-block {
	border-radius: 4px;
	background-color: #f7f3e0;
	padding: 24px 20px;
	position: relative;
	margin-top: 25px;
	.main-block__table {
		margin-top: 2px;
		z-index: 10;
		position: relative;
	}
	.main-block__table-col:nth-child(1) {
		width: 45%;
	}
}

.top-casino-viking {
	position: absolute;
	right: 47px;
	top: -40px;
}

.blockquote {
	border-radius: 4px;
    background-color: #e8f3f6;
    padding: 22px 21px 19px 40px;
    margin-top: 28px;
    margin-bottom: 35px;
    position: relative;
	p {
		margin: 0;
		font-size: 18px;
		font-weight: 700;
		color: #006ba0;
		font-style: italic;
		z-index: 9;
		position: relative;
	}
	span {
		margin-top: 14px;
		display: block;
		color: #006ba0;
		font-size: 18px;
		z-index: 9;
		position: relative;
	}
	&-quote {
		position: absolute;
		left: 22px;
		top: 18px;
	}
}

.main-block__list {
	ul {
		margin-top: 25px;
		margin-bottom: 28px;
		li {
			font-size: 18px;
			color: #3c3c3c;
			margin-bottom: 18px;
			padding-left: 34px;
			position: relative;
			img {
				position: absolute;
				left: 0;
				top: 1.5px;
			}
		}
	}
}

.main-block__list-white {
	ul {
		li {
			padding-left: 34px;
			img {
				top: -2.5px;
				left: -5px;
			}
		}
	}
	
}

.blockquote-green {
	border-radius: 4px;
	background-color: #e6f6e0;
	padding: 20px;
	margin-top: 25px;
	margin-bottom: 35px;
	display: flex;
	justify-content: space-between;
	position: relative;
	p {
		padding: 0;
		margin: 0;
		box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
		background-color: #ffffff;
		padding: 18px 20px;
		color: #006ba0;
		font-style: italic;
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
		max-width: 717px;
		width: 80%;
		position: relative;
		&:after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 20px 20px 0 20px;
			border-color: #fff transparent transparent transparent;
			content: '';
			position: absolute;
			right: -20px;
			top: 20px;
		}
	}
	span {
		color: #006ba0;
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		margin-left: 35px;
		max-width: 140px;
		width: 20%;
		margin-top: 5px;
	}
}

.blockquote-blue {
	border-radius: 4px;
	background-color: #e8f3f6;
	padding: 17px 74px 23px 19px;
    position: relative;
    margin-top: 23px;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 4px;
		height: 100%;
		content: '';
		background-color: #00a1e1;
		border-radius: 4px 0 0 4px;
	}
	p {
		color: #006ba0;
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
		margin: 0;
		padding: 0;
	}
}

.alert-text {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 17px;
	border-radius: 4px;
	background-color: #00a1e1;
	margin-top: 20px;
	p {
		margin: 0;
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
		margin-left: 16px;
	}
}

.blockquote-blue__img {
	position: relative;
	margin-bottom: 35px;
	p {
		max-width: 73%;
	}
	.blockquote-viking {
		position: absolute;
		bottom: 0px;
		right: 27px;
	}
}

.main-block__double {
	margin-top: 20px;
	margin-bottom: 32px;
	&-content {
		display: flex;
		justify-content: space-between;
	}
	&-block {
		width: 48.5%;
		p {
			font-size: 14px;
			line-height: 18px;
			margin-top: 17px;
		}
		.blockquote-blue {
			padding-top: 10px;
			padding-bottom: 10px;
			padding-right: 22px;
			margin-top: 10px;
			margin-bottom: -10px;
			p {
				margin: 0;
			}
		}
		.main-block__list {
			ul {
				margin-top: 18px;
				margin-bottom: 10px;
			}
			li {
				font-size: 14px;
				line-height: 18px;
				padding-left: 25px;
				margin-bottom: 12px;
				img {
					width: 15px;
				}
			}
		}
		.question-block h3 {
			font-size: 20px;
			margin-bottom: 0px;
		}
		.question-block {
			padding: 14px 15px;
			margin-top: 19px;
			padding-bottom: 21px;
		}
		.question-block__item-title {
			font-size: 14px;
			padding: 15px 0;
		}
		.question-block__item-content {
			padding-bottom: 13px;
			p {
				margin: 0;
			}
		}
		.question-block__item {
			padding: 0 15px;
			margin-top: 5px;
		}
		.question-block__item.active .question-block__item-title {
			padding-bottom: 7px;
		}
	}
}

.comparison-block {
	border-radius: 4px;
	background-color: #e8f3f6;
	margin-top: 25px;
    margin-bottom: 27px;
    padding: 27px 20px;
	&__items {
		display: flex;
		justify-content: space-between;
		margin-top: 18px;
	}
	&__item {
		width: 50%;
		padding: 14px 19px;
		min-height: 484px;
		box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		background-color: #ffffff;
		z-index: 10;
		&:nth-child(2) {
			background-color: #f3f9fb;
			z-index: 1;
		}
		&-title {
			display: flex;
			align-items: center;
			h4 {
				margin-top: 15px;
			}
		}
		.main-block__list-border {
			min-height: 192px;
			border-bottom: 1px solid rgba($color: #3c3c3c, $alpha: .1);
		}
	}
	.main-block__list ul {
		margin-top: 20px;
	}
}

.comparison-block__item-blue {
	background-color: #0089bf;
	h4, .main-block__list ul li {
		color: #fff;
	}
}

.comparison-block__item-white {
	background-color: #fff!important;
}

.look-block {
	margin-top: 25px;
	border-radius: 4px;
	background-color: #edf0fc;
	padding: 27px 26px 43px 21px;
	margin-bottom: 35px;
	&__items {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;
		padding-left: 17px;
		padding-right: 9px;
	}
	&__item {
		text-align: center;
		&-img {
			width: 160px;
			height: 160px;
			box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		span {
			display: block;
			color: #0089bf;
			font-size: 48px;
			font-weight: 700;
			line-height: 30px;
			margin-top: 20px;
			margin-bottom: 10px;
		}
		p {
			color: #3c3c3c;
			font-size: 18px;
			font-weight: 400;
			margin: 0;
		}
	}
}

.registrering-block {
	margin-top: 23px;
	margin-bottom: 35px;
	border-radius: 4px;
	position: relative;
	background-image: url(../img/registrering-bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	padding: 28px 57px 17px 20px;
	h3 {
		color: #fff;
		z-index: 11;
		position: relative;
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #0089bf;
		opacity: 0.9;
	}
	&__items {
		z-index: 11;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.main-block__list ul li {
			color: #ffffff;
			font-size: 18px;
			font-weight: 700;
		}
	}
	.main-block__list-white ul li {
		padding-left: 40px;
	}
	.registrering-img {
		margin-top: -20px;
	}
}


.conclusion-block {
	border-radius: 4px;
	background-color: #e8f3f6;
	margin-top: 35px;
	margin-bottom: 37px;
	padding: 27px 29px 39px 20px;
	&__title {
		display: flex;
		align-items: baseline;
		margin-bottom: 17px;
	}
	&-stars {
		display: flex;
		margin-left: 22px;
		align-items: flex-end;
		span {
			color: #8c8c8c;
			font-size: 14px;
			font-weight: 400;
			margin-right: 7px;
		}
	}
	&__content {
		display: flex;
		justify-content: space-between;
	}
	&__text {
		max-width: 480px;
		width: 60%;
		p {
			color: #3c3c3c;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			span {
				font-weight: 700;
			}
		}
	}
	&__quote {
		width: 40%;
		position: relative;
		p {
			color: #006ba0;
			font-style: italic;
			font-size: 24px;
			font-weight: 700;
			line-height: 36px;
			z-index: 100;
			position: relative;
		}
		span {
			font-size: 18px;
			font-weight: 400;
			display: block;
			color: #006ba0;
			margin-top: 10px;
		}
		.blockquote-quote {
			left: -10px;
		}
	}
	.btn-small {
		max-width: 229px;
		margin: auto;
		margin-top: 21px;
		img {
			margin-right: 5px;
		}
	}
	.main-block__table-stars li {
		margin-right: 6px;
	}
}

.registrering-block__white {
	background-image: none;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15);
	margin-bottom: 35px;
	margin-top: 31px;
	padding: 28px 47px 17px 20px;
	&:before {
		background-color: #ffffff;
	}
	h3, .main-block__list ul li {
		color: #006ba0;
	}
	.registrering-img {
		margin-top: -37px;
	}
}

.security-block {
	border-radius: 4px;
	background-color: #e8f3f6;
	padding: 30px 38px 40px 20px;
	margin-top: 21px;
	p {
		margin: 0;
		line-height: 24px;
	}
	&__first {
		margin-top: 81px;
	}
	&__last {
		margin-bottom: 35px;
	}
	&__white {
		background-color: #fff;
		border: 1px solid #d6e7f0;
		padding-top: 24px;
		.security-block__checks img {
			margin-left: 5px;
			margin-top: 10px;
		}
	}
	&__content {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
	&__info {
		max-width: 648px;
		width: 76%;
	}
	.question-block__item {
		padding-right: 15px;
		padding-left: 19px;
		&:first-of-type {
			margin-top: 22px;
		}
		&-title {
			padding: 14px 0;
			p {
				color: #006ba0;
				font-size: 20px;
				font-weight: 700;
				line-height: 36px;
				display: flex;
				align-items: center;
				margin: 0;
			}
		}
		&-content {
			padding-bottom: 16px;
		}
	}
	.main-block__table-num {
		width: 32px;
		height: 32px;
		margin-right: 17px;
	}
	&__checks {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 20%;
		img {
			align-self: center;
			margin-left: 15px;
		}
		&-list {
			margin-top: auto;
			text-align: center;
		}
		&-title {
			display: block;
			color: #006ba0;
			font-size: 14px;
			font-weight: 400;
			line-height: 30px;
		}
		&-step {
			box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			background-color: #ffffff;
			color: #01ad19;
			font-size: 18px;
			font-weight: 700;
			padding: 10px 17px 10px 19px;
			display: inline-block;
			margin-bottom: 10px;
		}
		&-circles {
			li {
				margin: auto;
				margin-bottom: 7px;
				span {
					width: 10px;
					height: 10px;
					background-color: #0089bf;
					opacity: 0.6;
					content: '';
					border-radius: 50%;
					display: block;
					margin: auto;
					&.security-block__checks-circle2 {
						opacity: 0.4;
					}
					&.security-block__checks-circle3 {
						opacity: 0.2;
					}
				}
				
			}
		}
		&-total {
			color: #006ba0;
			font-size: 14px;
			font-weight: 400;
			display: block;
			strong {
				font-size: 18px;
				font-weight: 700;
			}
		}
	}
}

.bonuses-block {
	margin-top: 34px;
    margin-bottom: 33px;
    border-radius: 4px;
    background-color: #e8f3f6;
    padding: 26px 20px 40px 20px;
	&__content {
		display: flex;
		justify-content: space-between;
		margin-top: 18px;
		p {
			font-size: 14px;
			line-height: 22px;		
		}
	}
	&__item {
		width: calc(50% - 15px);
	}
	.question-block__item-title {
		padding: 18px 0px;
		
		p {
			display: flex;
			align-items: center;
			margin: 0;
			color: #006ba0;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			max-width: 90%;
			padding-left: 40px;
			img {
				margin-right: 15px;
				transform: none!important;
				position: absolute;
				left: 15px;
				top: 25px;
			}
		}
	}
	.question-block__item-title:hover img {
		filter: none!important;
	}
}

.security-block__checks-circles li {
	transition: .5s linear;
	&.active {
		transform: rotateX(180deg);
	}
	
}

.top-block {
	margin-top: 20px;
	margin-bottom: 38px;
	&__content {
		margin-top: 22px;
		display: flex;
		justify-content: space-between;
		margin-left: -10px;
		margin-right: -10px;
	}
	&__item {
		border-radius: 4px;
		background-color: #e8f3f6;
		width: calc(33.3% - 20px);
		margin: 0 10px;
		&-title {
			display: block;
			color: #006ba0;
			font-size: 24px;
			font-weight: 700;
			line-height: 36px;
			margin-bottom: 4px;
			&:hover {
				color: #00a1e1;
			}
		}
		&-subtitle {
			display: block;
			color: #01ad19;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			
		}
		&-info {
			padding: 10px 20px 30px 20px;
			p {
				color: #787878;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				margin: 0;
				margin-top: 7px;
				margin-bottom: 19px;
			}
		}
		.btn-code {
			width: 100%;
			height: 44px;
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
			border-radius: 3px;
			background-color: #ffffff;
			line-height: 44px;
			color: #006ba0;
			font-size: 14px;
			font-weight: 500;
			line-height: 36px;
			margin-top: 11px;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				color: #00a1e1;
			}
		}
	}
	&--mod {
		.top-block__item-info {
			padding: 6px 19px 19px;
			p {
				font-size: 12px;
				line-height: 18px;
				margin-top: 12px;
			}
		}
		.top-block__item-title {
			font-size: 20px;
			line-height: 36px;
			margin-bottom: 1px;
		}
		.top-block__item-subtitle {
			font-size: 18px;
			line-height: 24px;
		}
		.btn {
			font-size: 18px;
			font-weight: 700;
			line-height: 18px;
			height: auto;
			padding-top: 9px;
			padding-bottom: 12px;		
		}
		.btn-code {
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			height: auto;
			padding-top: 8px;
			padding-bottom: 10px;
		}
		.top-block__item {
			width: calc(25% - 20px);
		}
	}
}

.reviewer-block {
	margin-top: 34px;
	margin-bottom: 23px;
	border: 1px solid #d6e7f0;
	border-radius: 4px;
	display: flex;
	align-items: center;
	width: max-content;
	img {
		display: block;
	}
	&__info {
		margin-left: 17px;
		padding-right: 48px;
		margin-top: -6px;
	}
	&__author {
		color: #3c3c3c;
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 15px;
		a {
			color: #006ba0;
			font-weight: 700;
			&:hover {
				color: #00a1e1;
			}
		}
	}
	&__update {
		font-size: 14px;
		color: #3c3c3c;
		a {
			color: #006ba0;
			&:hover {
				color: #00a1e1;
			}
		}
	}
}

.fixed-block {
	position: absolute;
    width: 100vw;
	left: 50%;
	transform: translateX(-50%);
	top: auto;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
	background-color: #ffffff;
	padding: 20px 0;
	&.fixed {
		position: fixed;
		left: 0;
		transform: none;
		width: 100%;
		top: 0;
		z-index: 500;
	}
	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__info {
		display: flex;
		align-items: center;
		span {
			color: #01ad19;
			font-size: 16px;
			font-weight: 700;
			line-height: 36px;
		}
	}
	&__title {
		color: #006ba0;
		font-size: 16px;
		font-weight: 700;
		line-height: 36px;
		margin: 0 20px;
		&:hover {
			color: #00a1e1;
		}
	}
	&__close {
		position: absolute;
		right: 5px;
		cursor: pointer;
		transition: $transition;
		&:hover {
			opacity: .7;
		}
	}
	.btn-small {
		max-width: 157px;
		font-size: 16px;
	}
}

.side-viking {
	position: absolute;
	right: -173px;
	top: 60%;
	&__dialog {
		position: absolute;
		top: -202px;
		left: 47px;
		width: 290px;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
		background-color: #ffffff;
		padding: 30px 20px 40px 20px;
		border-radius: 4px;
		z-index: -1;
		&:after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 50px 50px 0 25px;
			border-color: #fff transparent transparent transparent;
			content: '';
			position: absolute;
			right: 42px;
			bottom: -12px;
			transform: rotateZ(-92deg);
		}
		&-title {
			color: #006ba0;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			display: block;
			margin-bottom: 5px;
		}
		p {
			color: #3c3c3c;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
		}
	}
}

.main-block__link-mob {
	display: none;
}

.rate-title {
	display: none;
}

.main-block__table-mobile-text {
	display: none;
}

@media screen and (max-width: 1600px){
	.side-viking {
		display: none;
	}
}

@media screen and (max-width: 992px){
	.wrapper {
		padding: 0;
	}
	.btn-small {
		max-width: 119px;
		font-size: 16px;
	}
	.main-block {
		padding: 35px 30px;
		padding-top: 42px;
	}
	.main-block p {
		font-size: 16px;
		line-height: 23px;
	}
	.main-block__link {
		font-size: 16px;
		line-height: 23px;
	}
	.main-block__table {
		margin-top: 12px;
	}
	.main-block__table-col:nth-child(1) {
		width: 38%;
	}
	.main-block__table-head {
		margin-bottom: 19px;
	}
	.main-block__table-block-bottom {
		font-size: 12px;
	    line-height: 18px;
		padding: 13px 20px;
		a {
			font-size: 12px;
	    	line-height: 18px;
		}
	}
	.main-block__link img {
		vertical-align: middle;
	}
	.main-block__link-mob {
		display: block;
		width: 289px;
		height: 40px;
		line-height: 36px;
		text-align: center;
		border-radius: 6px;
		border: 2px solid rgba($color: #000000, $alpha: .1);
		font-size: 16px;
		color: #006ba0;
		font-weight: 700;
		margin: auto;
		margin-top: 20px;
		margin-bottom: 15px;
	}
	.question-block {
		margin-left: -30px;
		margin-top: 25px;
		width: calc(100% + 60px);
		padding: 23px 30px;
		padding-bottom: 30px;
	}
	.question-block__item {
		width: 79%;
	}
	.online-casino-block {
		margin-top: 35px;
	}
	.top-casino-block {
		margin-left: -30px;
		width: calc(100% + 60px);
		padding: 24px 30px;
		padding-bottom: 30px;
		margin-top: 33px;
		margin-bottom: 35px;
		.main-block__table-num {
			position: absolute;
			left: -14px;
			top: 50%;
			transform: translateY(-50%);
		}
		.main-block__table-block .main-block__table-row {
			position: relative;
		}
		.main-block__table-head {
			margin-bottom: 7px;
		}
		.main-block__table-col:nth-child(1) {
			padding-left: 31px;
		}
		.main-block__table-col:nth-child(1) a {
			width: 90%;
		}
		.main-block__table-col:nth-child(1) {
			width: 38%;
		}
	}
	blockquote, .blockquote, .blockquote-green,
	.blockquote-blue, .alert-text, .comparison-block,
	.look-block, .registrering-block,
	.conclusion-block, .security-block,
	.bonuses-block {
		margin-left: -30px;
		width: calc(100% + 60px);
		padding: 24px 30px;
		padding-bottom: 30px;
	}
	.blockquote {
		margin-bottom: 20px;
	}
	.blockquote-quote {
		left: 0;
	}
	.blockquote-green {
		padding: 20px 30px;
		margin-bottom: 25px;
		p {
			font-size: 18px;
		}
		span {
			margin-left: 33px;
			margin-top: -7px;
			font-size: 16px;
		}
	}
	.main-block__list ul {
		margin-top: 22px;
	}
	.main-block__list ul li {
		padding-left: 29px;
	}
	.blockquote-blue {
		padding: 20px 30px;
		margin-bottom: 25px;
	}
	.alert-text {
		margin-top: 10px;
		padding: 20px 26px;
		align-items: flex-start;
		p {
			font-size: 18px;
		}
	}
	.blockquote-blue__img .blockquote-viking {
		right: 15px;
	}
	.main-block__double {
		margin-top: 55px;
		margin-bottom: 25px;
		.blockquote-blue,
		.question-block {
			margin-left: 0;
			width: 100%;
		}
		p {
			line-height: 18px;
			font-size: 14px;
		}
		.question-block__item {
			width: 100%;
		}
		.blockquote-blue {
			padding: 7px 20px;
			margin-top: 17px;
			margin-bottom: 20px;
		}
	}
	.main-block__double-content {
		margin-top: 10px;
	}
	.comparison-block__item-title h4 {
		margin-top: 4px;
		margin-left: 6px;	
	}
	h4 {
		line-height: 27px;
	}
	.comparison-block .main-block__list ul {
		margin-top: 17px;
		li {
			font-size: 16px;
			margin-bottom: 20px;
		}
	}
	.comparison-block__item .main-block__list-border {
		min-height: 206px;
	}
	.look-block__items {
		padding: 0;
	}
	.look-block {
		margin-bottom: 40px;
	}
	.registrering-block {
		margin-bottom: 25px;
	}
	.conclusion-block {
		margin-top: 30px;
		margin-bottom: 25px;
	}
	.conclusion-block__text {
		width: 50%;
	}
	.conclusion-block__quote {
		width: 47%;
		margin-top: 30px;
	}
	.conclusion-block__quote p {
		font-size: 24px;
		line-height: 36px;
	}	
	.conclusion-block .btn-small {
		margin-top: 10px;
		font-size: 18px;
	}
	.registrering-block__white {
		.main-block__list ul li {
			padding-left: 38px;
		}
	}
	.rate-title {
		display: block;
		margin-top: 94px;
	}
	.security-block__first {
		margin-top: 25px;
	}
	.security-block {
		padding-bottom: 40px;
		.question-block__item {
			width: 96%;
		}
	}
	.security-block .question-block__item-title p {
		font-size: 18px;
	}
	.security-block__checks {
		width: 29%;
	}
	.security-block__last {
		margin-bottom: 25px;
	}
	.bonuses-block {
		margin-bottom: 28px;
		.question-block__item {
			width: 100%;
		}
	}
	.bonuses-block .question-block__item-title p {
		font-size: 16px;
	}
	.top-block__item-title {
		font-size: 20px;
		margin-bottom: 0;
	}
	.top-block__item-subtitle {
		font-size: 18px;
		line-height: 23px;
	}
	.top-block__item-info p {
		font-size: 12px;
		margin-top: 11px;
		margin-bottom: 13px;
		line-height: 20px;
	}
	.top-block {
		margin-bottom: 25px;
		.btn {
			font-size: 18px;
			line-height: 18px;
			padding-top: 14px;
		}
		.btn-code {
			text-align: center;
			line-height: 18px;
			font-weight: 500;
			height: 54px;
		}
	}
	.top-block__item-info {
		padding: 10px 20px 20px 20px;
	}
	.top-block--mod {
		display: none;
	}
	.desktop-text {
		display: none;
	}
	.reviewer-block__author {
		font-size: 16px;
		margin-bottom: 12px;
	}
	.reviewer-block {
		margin-bottom: 35px;
	}
	.footer-logos {
		li {
			margin: 0 15px;
			&:first-of-type {
				margin-right: 40px;
			}
			&:nth-child(2) {
				margin-right: 25px;
			}
		}
		ul {
			padding-left: 30px;
    		padding-right: 0px;
		}
	}
}
	


@media screen and (max-width: 767px) {
	.main-block {
		padding: 35px 15px;
		padding-top: 27px;
	}
	.main-block h2 {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 0;
	}
	.main-block p {
		margin-top: 18px;
		line-height: 20px;
	}
	.main-block__table-head {
		display: none;
	}
	.main-block__table-col:nth-child(1) {
		width: 100%;
		padding-left: 15px;
	}
	.main-block__table-block .main-block__table-row {
		flex-wrap: wrap;
		position: relative;
		padding-bottom: 80px;
	}
	.main-block__table-col:nth-child(4) {

		.btn {
			position: absolute;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
			bottom: 21px;
			max-width: 91%;
		}
	}
	.main-block__table-col:nth-child(1) a {
		font-size: 16px;
	}
	.main-block__table-col:nth-child(1) a img {
		margin-right: 13px;
	}
	.main-block__table-col:nth-child(2) {
		width: 52%;
		padding-left: 15px;
	}
	.main-block__table-col:nth-child(3),
	.main-block__table-col:nth-child(4) {
		width: 24%;
		flex-direction: column;
		img {
			display: block;
		}
	}
	.main-block__table-mobile-text {
		display: block;
		color: #8c8c8c;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		margin-top: 14px;
		margin-bottom: 5px;
	}
	.main-block__table-block .main-block__table-row {
		align-items: flex-start;
	}
	.main-block__table-bonus {
		line-height: 20px;
	}
	.main-block__table-block-bottom {
		line-height: 16px;
		padding: 9px 15px;
	}
	.main-block {
		padding: 23px 15px;
		padding-top: 27px;
	}
	h3 {
		font-size: 20px;
		line-height: 25px;
	}
	.question-block {
		padding-top: 10px;
	}
	.question-block h3 {
		margin-bottom: 10px;
	}
	.question-block__item {
		width: 100%;
	}
	.question-block__item-title {
		font-size: 16px;
		padding: 17px 0;
	}
	.question-block__item {
		padding: 0 15px;
	}
	.question-block__item-content {
		padding-bottom: 15px;
	}
	.question-block__item-content p {
		margin-top: 0;
	}
	.online-casino-block {
		margin-top: 30px;
	}
	.top-casino-viking {
		display: none;
	}
	.top-casino-block .main-block__table-col:nth-child(1) {
		width: 100%;
		padding-left: 15px;
	}
	.top-casino-block .main-block__table {
		margin-top: 30px;
	}
	.top-casino-block .main-block__table-num {
		top: -15px;
		left: 50%;
		transform: translateX(-50%);
	}
	.main-block__table-block {
		margin-top: 25px;
	}
	.top-casino-block {
		margin-bottom: 25px;
	}
	.blockquote {
		padding-top: 15px;
    	padding-bottom: 15px;
		p {
			margin: 0;
		}
		span {
			font-size: 16px;
		}
	}
	.blockquote {
		margin-bottom: 30px;
	}
	.main-block__list ul li {
		font-size: 16px;
		padding-left: 32px;
		line-height: 19px;
		margin-bottom: 13px;
	}
	.blockquote-green {
		flex-direction: column;
		padding: 5px 30px;
		p {
			width: 100%;
			font-size: 16px;
			padding: 18px 15px;
		}
		span {
			width: 100%;
			max-width: 100%;
			text-align: right;
			margin-left: auto;
			margin-top: 20px;
			font-size: 16px;
		}
	}
	.blockquote-green p:after {
		right: 10px;
		bottom: -9px;
		top: auto;
		transform: rotateZ(90deg);
	}
	.blockquote-blue:before {
		left: 15px;
	}
	.blockquote-blue {
		padding: 15px 30px;
		p {
			margin-top: 0;
		}
	}
	.alert-text {
		margin-top: 25px;
		padding: 16px 26px;
		margin-bottom: 25px;
		img {
			width: 47px;
		}
		p {
			font-size: 16px;
			margin-top: 0;
			margin-left: 5px;
		}
	}
	.blockquote-blue__img,
	.mob-delete {
		display: none;
	}
	.main-block__double {
		display: none;
	}
	.comparison-block__items {
		flex-wrap: wrap;
	}
	.comparison-block__item {
		width: 100%;
		ul {
			margin-bottom: 0;
		}
	}
	h4 {
		font-size: 18px;
		line-height: 22px;
	}
	.comparison-block__item-title h4 {
		margin-top: 8px;
		margin-left: 1px;
	}
	.comparison-block .main-block__list ul li {
		margin-bottom: 13px;
	}
	.comparison-block__item {
		padding: 14px 15px;
	}
	.comparison-block__item .main-block__list-border {
		min-height: auto;
		ul {
			margin-bottom: 20px;
		}
	}
	.look-block__items {
		flex-wrap: wrap;
		margin-top: 15px;
	}
	.look-block__item {
		width: 50%;
		margin-bottom: 20px;
		span {
			font-size: 36px;
			line-height: 30px;
			margin-bottom: 1px;
		}
		p {
			font-size: 16px;
			max-width: 90%;
			margin: auto;
		}
	}
	.look-block__item-img {
		width: 123px;
		height: 123px;
		margin: auto;
		img {
			width: 65%;
		}
	}
	.look-block {
		padding-bottom: 5px;
		margin-bottom: 25px;
	}
	.registrering-block__items {
		flex-direction: column-reverse;
		ul {
			margin-top: 0;
		}
	}
	.registrering-block .registrering-img {
		margin-top: 3px;
	}
	.registrering-block .main-block__list-white ul li {
		padding-left: 35px;
		font-size: 16px;
	}
	.registrering-block {
		padding-bottom: 0;
	}
	.conclusion-block__content,
	.conclusion-block-stars {
		flex-direction: column;
	}
	.conclusion-block__text,
	.conclusion-block__quote {
		width: 100%;
	}
	.conclusion-block-stars {
		align-items: flex-start;
		span {
			font-size: 12px;
			margin-bottom: 5px;
		}
	}
	.conclusion-block__title {
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 12px;
	}
	.conclusion-block {
		padding-top: 13px;
	}
	.conclusion-block__quote p {
		font-size: 20px;
	}
	.conclusion-block__quote {
		margin-top: 0;
	}
	.conclusion-block__quote span {
		font-size: 16px;
	}
	.conclusion-block .btn-small {
		margin-top: 21px;
		font-size: 16px;
	}
	.registrering-block__white {
		.registrering-img {
			margin-top: 17px;
			margin-bottom: 18px;
		}
		.main-block__list ul li {
			padding-left: 27px;
		}
	}
	.rate-title {
		margin-top: 20px;
	}
	.security-block__first {
		margin-top: 15px;
	}
	.security-block__content {
		flex-direction: column;
		flex-wrap: wrap;
		margin-top: 0;
	}
	.security-block__info {
		max-width: 100%;
		width: 100%;
	}
	.security-block .question-block__item {
		width: 100%;
	}
	.security-block__checks {
		width: 100%;
		margin-top: 29px;
		justify-content: space-around;
		flex-direction: row;
		align-items: center;
		img {
			margin-left: 0;
		}	
		.security-block__checks-list {
			margin-top: 0;
		}
	}
	.security-block .question-block__item:first-of-type {
		margin-top: 17px;
	}
	.security-block .question-block__item-title p {
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
	}
	.security-block .question-block__item-title {
		padding: 12px 0;
	}
	.security-block .question-block__item {
		padding-right: 15px;
		padding-left: 15px;
	}
	.security-block .main-block__table-num {
		min-width: 28px;
		width: 28px;
		height: 28px;
		margin-right: 11px;
	}
	.security-block {
		padding-bottom: 28px;
	}
	.bonuses-block__item {
		width: 100%;
	}
	.bonuses-block__content {
		flex-wrap: wrap;
		flex-direction: column;
		margin-top: 10px;
	}
	.bonuses-block .question-block__item-title {
		padding: 11px 0px;
	}
	.bonuses-block .question-block__item-title p {
		line-height: 20px;
		padding-left: 40px;
		position: relative;
		img {
			position: absolute;
			left: 0;
			top: 17px;
		}
	}
	.bonuses-block__item:last-of-type {
		.question-block__item {
			&:last-of-type {
				p {
					img {
						left: 5px;
					}
				}
			}
		}
	}
	.top-block__item {
		width: 100%;
		margin: auto;
		max-width: 290px;
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.top-block__content {
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0 10px;
	}
	.top-block {
		margin-top: 59px;
	}	
	.top-block__item-info {
		padding: 10px 15px 30px 15px;
		p {
			margin-top: 11px;
			margin-bottom: 17px;
		}
	}
	.top-block .btn {
		height: 50px;
		font-size: 16px;
		max-width: 100%;
		width: 100%;
		padding-top: 16px;
	}
	.top-block .btn-code {
		font-size: 14px;
	}
	.comparison-block__item {
		min-height: auto;
	}
	.bonuses-block .question-block__item-title p img {
		top: 13%;
	}
	.reviewer-block {
		width: 100%;
	}
	.reviewer-block__info {
		width: 60%;
		padding: 13px 0;
	}
	.reviewer-block__author {
		margin-bottom: 12px;
		a {
			display: block;
			line-height: 14px;
		}
	}
	.reviewer-block img {
		height: 100%;
	}
	.reviewer-block {
		align-items: stretch;
	}
	.reviewer-block__update {
		line-height: 15px;
	}
	.reviewer-block {
		margin-bottom: 38px;
	}
	.footer-logos ul {
		padding: 25px;
		padding-bottom: 9px;
	}
}



@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-BoldItalic.eot');
    src: url('../fonts/Ubuntu/Ubuntu-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-BoldItalic.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-BoldItalic.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
    font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Italic.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Italic.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Italic.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype');
    font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Regular.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Regular.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Bold.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Bold.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Medium.eot');
    src: url('../fonts/Ubuntu/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Ubuntu/Ubuntu-Medium.woff2') format('woff2'),
        url('../fonts/Ubuntu/Ubuntu-Medium.woff') format('woff'),
        url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
    font-weight: 500;
	font-style: normal;
	font-display: swap;
}